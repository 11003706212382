<template>
  <div style="overflow: hidden;">
    <b-modal id="modal-session-app" ok-only ok-variant="warning" ok-title="Login" modal-class="modal-session-app" centered
      title="Expired !" @ok="gotologin()">
      <b-card-text>Session Expired</b-card-text>
    </b-modal>
    <div class="container">
      <validation-observer>
        <b-form id="searchForTheKeyword" @submit.prevent="getSuggests()">
          <b-card class="d-flex mb-0">
            <b-row class="border rounded p-2 justify-content-center align-items-center mx-0">
              <b-col cols="12" md="3">
                <h3 class="mb-2 mb-md-0 ">Keyword Search</h3>
              </b-col>
              <b-col cols="12" md="7">
                <b-container>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group class="mb-0">
                        <input type="text" class="form-control" placeholder="Enter The Keyword." v-model="keywordName" />
                        <small class="text-danger keyword-name-error"></small>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
              <b-col cols="12" md="2">
                <b-container>
                  <b-row>
                    <b-col cols="12" class="d-flex justify-content-center  mt-2 mt-md-0">
                      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-info" class="mr-1 circle"
                        type="submit" v-b-tooltip.hover title="Search">
                        <feather-icon icon="SearchIcon" size="14" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
      <div class="mt-3">
        <b-card v-if="isloading || items.length > 0">
          <b-table v-if="!isloading && items.length > 0" striped hover responsive :per-page="perPage"
            :current-page="currentPage" :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
            <template #cell(DifficultyScore)="data">
              <span class="mr-1">
                {{ data.item.DifficultyScore }} / 10
              </span>
              <img class="img-score" v-if="data.item.DifficultyScore >= 0 && data.item.DifficultyScore < 2.5"
                :src="prgressBarCircle1">
              <img class="img-score" v-if="data.item.DifficultyScore >= 2.5 && data.item.DifficultyScore < 5"
                :src="prgressBarCircle2">
              <img class="img-score" v-if="data.item.DifficultyScore >= 5 && data.item.DifficultyScore < 7.5"
                :src="prgressBarCircle3">
              <img class="img-score" v-if="data.item.DifficultyScore >= 7.5 && data.item.DifficultyScore < 10"
                :src="prgressBarCircle4">
            </template>
            <template #cell(trafficScore)="data">
              <span class="mr-1">
                {{ data.item.trafficScore }} / 10
              </span>
              <img class="img-score" v-if="data.item.trafficScore >= 0 && data.item.trafficScore < 2.5"
                :src="prgressBarCircle1_1">
              <img class="img-score" v-if="data.item.trafficScore >= 2.5 && data.item.trafficScore < 5"
                :src="prgressBarCircle2_1">
              <img class="img-score" v-if="data.item.trafficScore >= 5 && data.item.trafficScore < 7.5"
                :src="prgressBarCircle3_1">
              <img class="img-score" v-if="data.item.trafficScore >= 7.5 && data.item.trafficScore < 10"
                :src="prgressBarCircle4_1">
            </template>
          </b-table>
          <b-skeleton-table v-if="isloading" :rows="10" :columns="4"
            :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BCard,
  BFormSelect,
  BTable,
  BInputGroup,
  BFormTextarea,
  BFormInput,
  BFormGroup,
  BFormFile,
  BImg,
  BForm,
  BInputGroupAppend,
  BCardText,
  BButtonGroup,
  VBTooltip,
  BPagination,
  BButton,
  VBHover,
  BContainer,
  BFormInvalidFeedback,
  BAspect,
  BSpinner,
  BSkeletonTable,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";
import { connectApi } from "@/helper/connectApi";
import Ripple from "vue-ripple-directive";
import originAxios from "axios";
import * as R from 'ramda';
import { estimatedFinalScore } from "@/helper/traffic";
import prgressBarCircle1 from '@/assets/images/25.svg';
import prgressBarCircle2 from '@/assets/images/50.svg';
import prgressBarCircle3 from '@/assets/images/75.svg';
import prgressBarCircle4 from '@/assets/images/100.svg';
import prgressBarCircle1_1 from '@/assets/images/25-1.svg';
import prgressBarCircle2_1 from '@/assets/images/50-1.svg';
import prgressBarCircle3_1 from '@/assets/images/75-1.svg';
import prgressBarCircle4_1 from '@/assets/images/100-1.svg';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormTextarea,
    BCard,
    BContainer,
    BFormInput,
    BFormGroup,
    BFormFile,
    BImg,
    BFormSelect,
    BInputGroupAppend,
    BForm,
    BTable,
    BCardText,
    BInputGroup,
    BModal,
    BButtonGroup,
    VBTooltip,
    BPagination,
    BButton,
    BAspect,
    BFormInvalidFeedback,
    BSpinner,
    BSkeletonTable
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-hover": VBHover,
    Ripple,
  },
  data() {
    return {
      prgressBarCircle1,
      prgressBarCircle2,
      prgressBarCircle3,
      prgressBarCircle4,
      prgressBarCircle1_1,
      prgressBarCircle2_1,
      prgressBarCircle3_1,
      prgressBarCircle4_1,
      titleScore: null,
      competitorScore: null,
      installsScore: null,
      ratingsScore: null,
      ageScore: null,
      keyword: null,
      totalScore: null,
      keyword: null,
      keywordName: null,
      keywordsFile: null,
      perPage: 300,
      pageOptions: [10, 25, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "desc",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      items: [],
      filterOn: [],
      fields: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "title",
          label: "Keyword",
        },
        {
          key: "trafficScore",
          label: "Traffic Score",
          sortable: true,
          class: "text-center"
        },
        {
          key: "DifficultyScore",
          label: "Difficulty Score",
          sortable: true,
          class: "text-center"
        },

      ],
      firstResult: [

      ],
      lastResult: [

      ],
      isloading: false,
      backupApps: [],
      mainKeywords: [],
      Keywords: [],
      keyApps: [],
      trafficScore: [],
      trafficScore1: [],
      language: "En",
      arabicChar: /[\u0600-\u06FF]/,

    };
  },
  mounted() {
    this.token = JSON.parse(localStorage.getItem("userAccess")).token;
    this.server = JSON.parse(localStorage.getItem("userAccess")).server;
  },
  methods: {
    async getSuggests() {
      this.isloading = true;
      const characters = [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ];
      const charactersAr = [
        "أ",
        "ب",
        "ت",
        "ث",
        "ج",
        "ح",
        "خ",
        "د",
        "ذ",
        "ر",
        "ز",
        "س",
        "ش",
        "ص",
        "ض",
        "ط",
        "ظ",
        "ع",
        "غ",
        "ف",
        "ق",
        "ك",
        "ل",
        "م",
        "ن",
        "ه",
        "و",
        "ي",
      ];

      let suggestions_primary = [];
      let finalKeywordList = [];
      // await originAxios
      //   .get(
      //     `https://sc${this.server}.clicklab.app/api/apps/?suggest=${this.keywordName.trim()}`
      //   )
      const response = connectApi(`https://sc${this.server}.clicklab.app/api/apps/?suggest=${this.keywordName.trim()}`);
      response
        .then((response) => {
          const { status } = response.data;
          if (status === "Unauthorized") {
            removeLocalStorageItems();
            this.$root.$emit("bv::show::modal", "modal-session-app");
            return;
          }
          const { results } = response.data;
          return results;
        })
        .then((results) => {
          if (results.length > 0) {
            results.forEach((result, index) => {
              suggestions_primary.push({
                ...result,
                position: index + 1,
              });
            });
          }
        })
        .catch(console.error);

      let suggestions_secondary = [];
      if (this.keywordName.match(this.arabicChar)) {
        const charRequests = charactersAr.map(async (char) => {
          try {
            const response = await connectApi(`https://sc${this.server}.clicklab.app/api/apps/?suggest=${this.keywordName.trim()} ${char}`);
            const { status } = response.data;
            if (status === "Unauthorized") {
              removeLocalStorageItems();
              this.$root.$emit(
                "bv::show::modal",
                "modal-session-app"
              );
              return [];
            }
            const { results } = response.data;
            return results;
          } catch (error) {
            console.error(error);
          };

          // const response = await connectApi(`https://sc${this.server}.clicklab.app/api/apps/?suggest=${this.keywordName.trim()} ${char}`)
          // response
          //   .then((response) => {
          //     const { status } = response.data;
          //     if (status === "Unauthorized") {
          //       removeLocalStorageItems();
          //       this.$root.$emit(
          //         "bv::show::modal",
          //         "modal-session-app"
          //       );
          //       return [];
          //     }
          //     const { results } = response.data;
          //     return results;
          //   }).catch((err) => {
          //     console.error(err)
          //   })
        }
        );

        await Promise.all(charRequests).then((charResults) => {
          charResults.forEach((results, index) => {
            if (results) {
              if (results.length > 0) {
                suggestions_secondary.push({
                  suggestions: results,
                  prefix: charactersAr[index],
                });
              }
            }

          });
        });
      } else {
        const charRequests = characters.map(async (char) => {
          try {
            const response = await connectApi(`https://sc${this.server}.clicklab.app/api/apps/?suggest=${this.keywordName.trim()} ${char}`);
            const { status } = response.data;
            if (status === "Unauthorized") {
              removeLocalStorageItems();
              this.$root.$emit(
                "bv::show::modal",
                "modal-session-app"
              );
              return [];
            }
            const { results } = response.data;
            return results;
          } catch (error) {
            console.error(error);
          };
        });
        await Promise.all(charRequests).then((charResults) => {
          charResults.forEach((results, index) => {
            if (results) {
              if (results.length > 0) {
                suggestions_secondary.push({
                  suggestions: results,
                  prefix: characters[index],
                });
              }
            }

          });
        });
      }


      try {
        suggestions_secondary.map((elem) => {
          elem.suggestions.map(e => {
            if (e.term.length <= 30) {
              finalKeywordList.push(e.term)
            }
          })
        })
        suggestions_primary.forEach(async (elem) => {
          await this.getApps(elem.term);
        })
        finalKeywordList.forEach(async (elem) => {
          await this.getApps(elem);
        })
      } catch (error) {
        console.error(error);
      }


      setTimeout(() => {
        // Your additional actions here
      }, 1000);

    },
    async getApps(app) {
      try {
        if (!this.firstResult.find(item => item === app)) {
          
          this.firstResult.push(app);
          // const { data } = await originAxios.get(`https://sc${this.server}.clicklab.app/api/apps/?q=${app}`);
          const { data } = await connectApi(`https://sc${this.server}.clicklab.app/api/apps/?q=${app}`);
          const apps = [...data.results];
          const newApps = [];
          for (const ap of apps) {
            const exist = this.backupApps.find(item => item.appId === ap.appId);
            if (!exist) {
              // const { data } = await originAxios.get(`https://sc${this.server}.clicklab.app/api/apps/${ap.appId}/?fullDetail=true`);
              const { data } = await connectApi(`https://sc${this.server}.clicklab.app/api/apps/${ap.appId}/?fullDetail=true`);
              newApps.push(data);
              this.backupApps.push(data);
            } else {
              newApps.push(exist);
            }
          }
          const test = {
            keyword: app,
            apps: newApps
          }
          this.keyApps.push(test);
          await Promise.all([
            this.getTtleMatches(app, newApps),
            this.getInstallsScore(newApps),
            this.getRating(newApps),
            this.getAge(newApps)
          ]);
          this.getScore(app);
        }
      } catch (error) {
        this.firstResult = this.firstResult.filter(item => item !== app);
        // Handle any errors that may occur during the requests.
      }
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    gotologin() {
      this.$router.push({ name: "auth-login" });
    },

    /* ----------------------------------- */
    getMatchType(keyword, title) {
      keyword = keyword.toLowerCase();
      title = title.toLowerCase();

      if (title.includes(keyword)) {
        return 'exact';
      }
      const matches = keyword.split(' ').map((word) => title.includes(word));
      if (R.all(R.identity, matches)) {
        return 'broad';
      }
      if (R.any(R.identity, matches)) {
        return 'partial';
      }
      return 'none';
    },
    getTtleMatches(keyword, apps) {
      const matches = R.pluck('title', apps).map((app) => this.getMatchType(keyword, app));
      const counts = {
        exact: R.filter(R.equals('exact'), matches).length,
        broad: R.filter(R.equals('broad'), matches).length,
        partial: R.filter(R.equals('partial'), matches).length,
        none: R.filter(R.equals('none'), matches).length
      };

      const score = (10 * counts.exact + 5 * counts.broad + 2.5 * counts.partial) / apps.length;
      this.titleScore = R.assoc('score', score, counts);
    },
    round(val) {
      return Math.round(val * 100) / 100;
    },
    score(min, max, value) {
      value = Math.min(max, value);
      value = Math.max(min, value);
      return this.round(1 + 9 * (value - min) / (max - min));
    },
    zScore(max, value) {
      return this.score(0, max, value);
    },
    getInstallsScore(apps) {
      const avg = R.sum(R.pluck('minInstalls', apps)) / apps.length;
      const max = 1000000;
      const score = this.zScore(max, avg);
      this.installsScore = { avg: avg, score: score };
    },
    getRating(apps) {
      const avg = R.sum(apps.map((app) => app.score || 0)) / apps.length;
      this.ratingsScore = {
        avg: avg,
        score: avg * 2
      }
    },

    iScore(min, max, value) {
      value = Math.min(max, value);
      value = Math.max(min, value);
      return this.round(1 + 9 * (max - value) / (max - min));
    },

    // inverted, zero based score
    izScore(max, value) {
      return this.iScore(0, max, value);
    },
    getDaysSince(date) {
      if (typeof date === 'string') {
        date = Date.parse(date);
      } else {
        date = date / 1000;
      }
      return Math.floor((Date.now() - date) / 86400000);
    },
    /*
    * Score the average time since last update among the top apps.
    */
    getAge(apps) {
      const updated = R.pluck('updated', apps).map(this.getDaysSince);
      const avg = R.sum(updated) / apps.length;
      const max = 500;
      const score = this.izScore(max, avg);

      this.ageScore = {
        avg: avg,
        score
      }
    },

    aggregate(weights, values) {
      const max = 10 * R.sum(weights);
      const min = 1 * R.sum(weights);
      const sum = R.sum(R.zipWith(R.multiply, weights, values));
      return this.score(min, max, sum);
    },
    getScore(app) {
      const TITLE_W = 4;
      // const COMPETITOR_W = 3;
      const INSTALLS_W = 5;
      const RATING_W = 2;
      const AGE_W = 1;
      this.totalScore = this.aggregate([TITLE_W, INSTALLS_W, RATING_W, AGE_W],
        [this.titleScore.score, this.installsScore.score, this.ratingsScore.score, this.ageScore.score]);
      this.lastResult.push({
        title: app,
        DifficultyScore: this.totalScore,
      })
      this.items = [];
      if (this.firstResult.length === this.lastResult.length) {
        // Create a map for faster lookup
        const map = new Map();
        for (const item of this.lastResult) {
          map.set(item.title, item.DifficultyScore);
        }
        this.items = this.firstResult.map((item, index) => ({
          index: index + 1,
          title: item,
          DifficultyScore: map.get(item) || 0 // If there's no match, default to 0
        }));


        (async () => {

          const scorePromises1 = this.keyApps.map(async (keyApp) => {
            const score1 = await estimatedFinalScore(keyApp.keyword, keyApp.apps, this.server);
            return { keyword: keyApp.keyword, score: score1 };
          });
          const [score1] = await Promise.all([Promise.all(scorePromises1)]);
          this.trafficScore1.push(...score1);
          const titleToIndexMap = {};
          this.items.forEach((item, index) => {
            titleToIndexMap[item.title] = index;
          });
          this.trafficScore1.sort((a, b) => {
            const titleA = a.score.keyword;
            const titleB = b.score.keyword;
            return titleToIndexMap[titleA] - titleToIndexMap[titleB];
          });

          for (let i = 0; i < this.items.length; i++) {
            // console.log("table :" + this.items[i].title + "\n traffic app " + this.trafficScore1[i].score.keyword);
            if (this.items[i].title === this.trafficScore1[i].score.keyword) {
              this.items[i].trafficScore = this.trafficScore1[i].score.score;

            }
            // this.items[i].LengthEstimated = this.trafficScore1[i].score.length;

            this.isloading = false
          }

        })();


        this.firstResult = [];
        this.lastResult = [];
        this.Keywords = [];
        this.backupApps = [];
        this.mainKeywords = [];
        this.keyApps = [];
        this.trafficScore1 = [];
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style  scoped>
.img-score {
  width: 25px;
  height: 25px;
}
</style>